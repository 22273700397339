import { EventBatchTransporter } from './EventBatchTransporter';

let eventListenerAttached = false;
/**
 *
 * Session Replay is intialized asynchronously, so we need to listen for when
 * it's ready and then patch the queued events with the session replay
 * properties. This way any events that haven't flushed yet will have the
 * session replay properties attached.
 */
export function handleSessionReplayInitOnce(
  eventBatchTransporter: EventBatchTransporter | undefined,
) {
  if (
    typeof window === 'undefined' ||
    eventListenerAttached ||
    !eventBatchTransporter
  ) {
    return;
  }

  eventListenerAttached = true;

  function handler() {
    const sessionReplayProperties =
      window.sessionReplay?.getSessionReplayProperties?.() || {};

    if (eventBatchTransporter && 'patchQueuedEvents' in eventBatchTransporter) {
      eventBatchTransporter?.patchQueuedEvents(sessionReplayProperties);
    }

    window.removeEventListener('wp-amp-session-replay:init', handler);
  }

  window.addEventListener('wp-amp-session-replay:init', handler);
}
