const nonProdOrigins = [
  'https://testwww.volvocars.com',
  'https://qawww.volvocars.com',
  'https://qaoxp.volvocars.com',
];

const prodOrigins = ['https://www.volvocars.com'];

export type TrackingServiceStatus = 'enabled' | 'disabled';

export interface TrackingServiceConfig {
  /**
   * Controls if events are sent to the tracking service or not.
   *
   * `enabled`: Send events to the tracking service in all supported origins(testwww, qawww and www), except localhost.
   *
   *  If your origin is not one of the supported origins, you should set `environment` manually.
   *
   * `disabled`: Do not send events to the tracking service.
   */
  status?: TrackingServiceStatus;
  /**
   * Tracking service enviornment to send events to.
   *
   * Defaults to automatically detecting the environment based on the origins
   * (testwww, qawww and www).
   *
   * Other subdomains should set this manually based on the environment.
   */
  environment?: 'prod' | 'non-prod';
}

export function getTrackingServiceEnvironment(config: TrackingServiceConfig) {
  const status = config.status;
  const environment = config.environment;

  if (process.env.NODE_ENV === 'test' || typeof window === 'undefined') {
    return;
  }

  if (status === 'disabled') {
    return;
  }

  if (environment) {
    return environment;
  }

  return nonProdOrigins.includes(window.location.origin)
    ? 'non-prod'
    : prodOrigins.includes(window.location.origin)
      ? 'prod'
      : undefined;
}
