import type { EventBatchTransporter } from './EventBatchTransporter';
import type { PageViewCache } from './PageViewCache';

type VolvoCarsAnalytics = Window['VolvoCarsAnalytics'];

export function attachOnVolvoCarsAnalyticsGlobal<
  T extends keyof VolvoCarsAnalytics,
>(key: T, value: VolvoCarsAnalytics[T]) {
  if (typeof window === 'undefined') {
    return;
  }

  if (!('VolvoCarsAnalytics' in window)) {
    Object.defineProperty(window, 'VolvoCarsAnalytics', {
      value: {},
      enumerable: true,
      writable: false,
      configurable: true,
    });
  }

  Object.defineProperty(window.VolvoCarsAnalytics, key, {
    value,
    enumerable: true,
    writable: false,
    configurable: true,
  });
}

declare global {
  interface Window {
    dataLayer: unknown[];
    VolvoCarsAnalytics: {
      getDimension: (node: string) => unknown;
      eventBatchTransporter?: EventBatchTransporter;
      pageViewCache?: PageViewCache;
    };
    sessionReplay?: {
      getSessionReplayProperties?: () => Record<string, any>;
    };
  }
}
