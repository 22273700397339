export function removeFalseyValuesFromObject<T extends Record<string, any>>(
  obj: T,
): Partial<T> {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (value || typeof value === 'boolean') {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as Partial<T>);
}
