// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-properties */
import { attachGetDimension } from './attachGetDimension';
import {
  type EventBatchTransporter,
  getEventBatchTransporter,
} from './EventBatchTransporter';
import { extractCampaignParams } from './extractCampaignParams';
import { handleSessionReplayInitOnce } from './handleSessionReplayInitOnce';
import { getPageViewCache } from './PageViewCache';
import {
  getTrackingServiceEnvironment,
  TrackingServiceConfig,
} from './shouldEnableTrackingService';
import { getTrackingSession, TrackingSession } from './TrackingSession';
import {
  type AccountCreatedData,
  type AddPaymentInfoData,
  type AddToCartData,
  type BeginCheckoutData,
  type CartViewData,
  type ChatInteractionData,
  type ConfigFinishData,
  type ConfigStartData,
  type CustomEventData,
  type EventStrict,
  type FormLoadData,
  type FormSubmitData,
  type LoginData,
  type PageViewData,
  type PurchaseData,
  type SiteNavInteractionData,
  type TrackingData,
  type ViewItemData,
} from './types';
import { removeFalseyValuesFromObject } from './utils/removeFalseyValuesFromObject';

attachGetDimension();

export interface TrackerOptions {
  /**
   * Forces analytics data from the tracker instance to be lowercase.
   *
   * @default true
   */
  forceLowerCase?: boolean;

  /**
   * Enable logging of sent analytics data in development.
   *
   * @default false
   */
  logging?: boolean;

  /**
   * Defer nonInteraction events until a `pageType` or `pageName` event is
   * present in the dataLayer.
   *
   * @default true
   */
  deferNonInteraction?: boolean;

  disabled?: boolean;

  trackingServiceConfig?: TrackingServiceConfig;
}

type TrackEventLegacy = 'interaction' | 'virtualPageView' | 'noninteraction';

export type TrackEvent =
  | 'chat_interaction'
  | 'web_vitals'
  | 'sitenav_interaction'
  | 'custom_event'
  | 'view_item'
  | 'page_view'
  | 'add_to_cart'
  | 'view_cart'
  | 'begin_checkout'
  | 'add_payment_info'
  | 'purchase'
  | 'config_start'
  | 'config_finish'
  | 'form_load'
  | 'form_submit'
  | 'search'
  | 'login'
  | 'account_created';

/**
 * Exposes methods to push analytics data for different events, with some
 * default data given when creating the tracker.
 */
export class Tracker {
  private readonly defaultEventData?: TrackingData | null;
  private readonly forceLowerCase: boolean;
  private readonly deferNonInteraction: boolean;
  private readonly logging: boolean;
  public disabled?: boolean;
  private readonly trackingSession?: TrackingSession;
  private readonly eventBatchTransporter?: EventBatchTransporter;
  private readonly trackingServiceEnvironment?: 'prod' | 'non-prod';

  constructor(
    eventData?: TrackingData | null,
    {
      forceLowerCase = true,
      logging = false,
      disabled = false,
      deferNonInteraction = true,
      trackingServiceConfig = { status: 'enabled' },
    }: TrackerOptions = {},
  ) {
    this.defaultEventData = eventData;
    this.forceLowerCase = forceLowerCase;
    this.deferNonInteraction = deferNonInteraction;
    this.logging = logging;
    this.disabled = disabled;

    // binding these to the instance to ensure that
    // `new Tracker({ eventCategory: 'Foo' }).interaction !== new Tracker({ eventCategory: 'Bar' }).interaction`
    this.interaction = this.interaction.bind(this);
    this.nonInteraction = this.nonInteraction.bind(this);
    this.pushCustomDimension = this.pushCustomDimension.bind(this);
    this.virtualPageView = this.virtualPageView.bind(this);
    this.trackingSession = getTrackingSession();
    this.eventBatchTransporter = getEventBatchTransporter();
    this.trackingServiceEnvironment = getTrackingServiceEnvironment(
      trackingServiceConfig,
    );
  }

  private sendGA4Event(
    event?: TrackEvent | TrackEventLegacy,
    eventData?: TrackingData,
  ) {
    const analytics: TrackingData = {
      ...(event === 'custom_event' && {
        eventCategory: 'not set',
        eventAction: 'not set',
        eventLabel: 'not set',
      }),
      ...this.defaultEventData,
      ...eventData,
      event: this.getEvent(event),
    };

    // Disable in tests for now as it'll require a major change in
    // all tests and releasing a major tracking version

    if (!this.trackingSession || !this.trackingServiceEnvironment) {
      this.push(analytics, { snakeCaseKeys: true });
    } else {
      this.trackingSession?.generateConfig().then((config) => {
        const data = this.push(analytics, { snakeCaseKeys: true });

        if (!data) {
          return;
        }

        const sessionReplayProperties =
          window.sessionReplay?.getSessionReplayProperties?.() || {};

        if (!window.sessionReplay?.getSessionReplayProperties) {
          handleSessionReplayInitOnce(this.eventBatchTransporter);
        }

        const pageViewCache = getPageViewCache();
        const lastPageViewData = pageViewCache?.get();

        const eventWithMeta = {
          ...(data.event !== 'page_view' &&
            lastPageViewData &&
            removeFalseyValuesFromObject({
              page_name: lastPageViewData.pageName,
              page_type: lastPageViewData.pageType,
              url: lastPageViewData.url,
              previous_page_name: lastPageViewData.previousPageName,
              sub_page_name: lastPageViewData.subPageName,
            })),
          ...data,
          session_id: config.sessionId,
          device_id: config.deviceId,
          event_id: crypto.randomUUID(),
          event_timestamp: new Date().toISOString(),
          user_agent: window.navigator.userAgent,
          ...sessionReplayProperties,
        };

        this.eventBatchTransporter?.queueEvent(eventWithMeta);
      });
    }
  }

  private push(
    eventData?: TrackingData,
    { snakeCaseKeys = false, lowerCaseValues = this.forceLowerCase } = {},
  ) {
    if (typeof window === 'undefined') {
      return;
    }

    if (!eventData || this.disabled) {
      return;
    }

    if (!('dataLayer' in window)) {
      // @ts-ignore
      window.dataLayer = [];
    }

    const transformedEventData = this.transformEventData(eventData, {
      snakeCaseKeys,
      lowerCaseValues,
    });

    if (process.env.NODE_ENV === 'development' && this.logging) {
      console.debug('[Analytics]', transformedEventData);
    }

    // GTM adds gtm.uniqueEventId to each event in the dataLayer
    // This creates a new object reference so this id doesn't get sent
    // to the tracking service
    window.dataLayer.push({ ...transformedEventData });

    return transformedEventData;
  }

  private transformEventData(
    eventData: TrackingData,
    { snakeCaseKeys = false, lowerCaseValues = this.forceLowerCase } = {},
  ) {
    const transformedEventData: TrackingData = {};

    for (let [key, value] of Object.entries(eventData)) {
      if (value === null || value === '') {
        continue;
      }

      if (typeof value === 'string' && lowerCaseValues) {
        value = value.toLowerCase();
      }

      if (snakeCaseKeys) {
        transformedEventData[camelToSnakeCase(key)] = keysToSnakeCase(value);
      } else {
        transformedEventData[key] = value;
      }
    }
    return transformedEventData;
  }

  // Wait for up to `retries` seconds for a pageType or pageName event in the
  // dataLayer before pushing the event
  private deferedNonInteraction(
    event: TrackEvent | TrackEventLegacy,
    eventData?: TrackingData,
    retries = 60,
  ) {
    const hasPageTypeEvent =
      Array.isArray(window.dataLayer) &&
      window.dataLayer.some(isPageTypeOrPageNameEvent);
    if (hasPageTypeEvent || retries <= 0) {
      this.sendGA4Event(event, eventData);
    } else {
      window.setTimeout(() => {
        this.deferedNonInteraction(event, eventData, --retries);
      }, 1000);
    }
  }

  /**
   * @deprecated Use customEvent instead.
   */
  nonInteraction(eventData?: TrackingData) {
    if (this.deferNonInteraction) {
      this.deferedNonInteraction(
        this.defaultEventData?.event || 'custom_event',
        eventData,
      );
    } else {
      this.sendGA4Event(
        this.defaultEventData?.event || 'custom_event',
        eventData,
      );
    }
  }

  /**
   * @deprecated Use customEvent instead.
   */
  interaction(eventData?: TrackingData) {
    this.sendGA4Event(
      this.defaultEventData?.event || 'custom_event',
      eventData,
    );
  }

  /**
   * @deprecated Use customEvent instead.
   */
  sendEvent(event?: TrackEvent, eventData?: TrackingData) {
    this.sendGA4Event(event, eventData);
  }

  private getEvent(event?: TrackEvent | TrackEventLegacy) {
    if (event) {
      return event;
    }
    if (this.defaultEventData?.event) {
      return this.defaultEventData.event;
    }
    return 'custom_event';
  }

  customEvent(customEventData: CustomEventData & EventStrict) {
    this.sendGA4Event('custom_event', customEventData);
  }

  siteNavInteraction(siteNavInteractionData: SiteNavInteractionData) {
    this.sendGA4Event('sitenav_interaction', siteNavInteractionData);
  }

  chatInteraction(chatInteractionData: ChatInteractionData) {
    this.sendGA4Event('chat_interaction', chatInteractionData);
  }

  pageView(pageViewData: PageViewData) {
    const hasPageViewData = Object.keys(pageViewData).length;

    if (!hasPageViewData) {
      return;
    }

    const campaignParams = extractCampaignParams();

    const data = {
      ...campaignParams,
      ...pageViewData,
      userAgent: window.navigator.userAgent,
    };

    const pageViewCache = getPageViewCache();

    if (
      this.trackingSession &&
      'getItem' in this.trackingSession &&
      this.trackingServiceEnvironment
    ) {
      this.trackingSession.getItem('previousPage').then((previousPage) => {
        const extendedData = {
          ...data,
          url: data.url || window.location.href,
          previousPageName: previousPage?.name || 'none',
          previousPageUrl: previousPage?.url || 'none',
        };

        pageViewCache?.set(extendedData);

        this.sendGA4Event('page_view', extendedData);

        if (typeof data.pageName === 'string') {
          this.trackingSession?.setItem('previousPage', {
            name: data.pageName,
            url: (data.url as string) || window.location.href,
          });
        }
      });
    } else {
      this.sendGA4Event('page_view', data);
    }
  }

  viewItem(viewItemData: ViewItemData) {
    this.sendGA4Event('view_item', viewItemData);
  }

  addToCart(addtoCartData: AddToCartData) {
    this.sendGA4Event('add_to_cart', addtoCartData);
  }

  cartView(cartViewData: CartViewData) {
    this.sendGA4Event('view_cart', cartViewData);
  }

  beginCheckout(beginCheckoutData: BeginCheckoutData) {
    this.sendGA4Event('begin_checkout', beginCheckoutData);
  }

  addPaymentInfo(addPaymentInfoData: AddPaymentInfoData) {
    this.sendGA4Event('add_payment_info', addPaymentInfoData);
  }

  purchase(purchaseData: PurchaseData) {
    this.sendGA4Event('purchase', purchaseData);
  }

  configStart(configStartData: ConfigStartData) {
    this.sendGA4Event('config_start', {
      ...configStartData,
      configurationStart: 1,
    });
  }

  configFinish(configFinishData: ConfigFinishData) {
    this.sendGA4Event('config_finish', {
      ...configFinishData,
      configurationFinish: 1,
    });
  }

  formLoad(formLoadData: FormLoadData) {
    this.sendGA4Event('form_load', { ...formLoadData, formLoads: 1 });
  }

  formSubmit(formSubmitData: FormSubmitData) {
    this.sendGA4Event('form_submit', {
      ...formSubmitData,
      formSubmission: 1,
    });
  }

  virtualPageView(eventData?: TrackingData) {
    this.sendGA4Event('page_view', eventData);
  }

  pushCustomDimension(name: string, value?: string | boolean | number) {
    this.push(
      { [name]: value },
      { snakeCaseKeys: true, lowerCaseValues: false },
    );
  }

  search(searchTerm: string) {
    this.sendGA4Event('search', {
      searchTerm: searchTerm,
    });
  }

  login(eventData?: LoginData) {
    this.sendGA4Event('login', eventData);
  }

  accountCreated(eventData?: AccountCreatedData) {
    this.sendGA4Event('account_created', eventData);
  }
}

function isPageTypeOrPageNameEvent(entry: unknown) {
  return (
    typeof entry === 'object' &&
    entry !== null &&
    ('pageType' in entry ||
      'pageName' in entry ||
      'page_type' in entry ||
      'page_name' in entry)
  );
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function keysToSnakeCase(
  trackingValue?: TrackingData | TrackingData[string],
): TrackingData | TrackingData[string] {
  if (typeof trackingValue !== 'object' || trackingValue === null) {
    return trackingValue;
  }
  let result: TrackingData = {};
  if (Array.isArray(trackingValue)) {
    return trackingValue.map((values) => keysToSnakeCase(values));
  }
  for (const [key, value] of Object.entries(trackingValue)) {
    result[camelToSnakeCase(key)] = keysToSnakeCase(value);
  }
  return result;
}
