import { Config } from './types';

// Use Elastic APM RUM agent instead of OpenTelemetry in the browser
// since elastic stack doesn't support client-side OpenTelemetry yet
export async function registerBrowserInstrumentation(config: Config) {
  if (typeof window === 'undefined' || !config.serverUrl) {
    return;
  }

  const apmAgent = await import('@elastic/apm-rum');

  const apm = apmAgent.init({
    ...config,
    active: !navigator.userAgent.includes('Googlebot'),
    logLevel:
      config.logLevel as import('@elastic/apm-rum').AgentConfigOptions['logLevel'],
  });

  if (config.addFilter) {
    apm.addFilter(config.addFilter);
  }
}
