import React from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { AppInitialProps, AppProps } from 'next/app';
import { StyleRenderer } from 'vcc-ui';
import {
  getVariablesFromPublicConfig,
  registerBrowserInstrumentation,
} from '@vcc-package/opentelemetry/browser';
import { GTMSnippets } from '@vcc-www/third-party-scripts/gtm-nextjs';
import ErrorBoundary from 'src/components/ErrorBoundary';
import withProviders from 'src/hoc/withProviders';
import { RawFeatureFlags } from 'src/utils/parseFeatureFlags';
import '../styles/utilities.css';
import '../styles/global.css';
import { getPublicEnv } from 'src/public-env';
import { MarketConfigurations } from '@vcc-package/retailer-selector';

registerBrowserInstrumentation({
  ...getVariablesFromPublicConfig(getPublicEnv()),
  addFilter: (payload) => {
    payload.transactions = payload.transactions.filter(
      (t: { type?: string; name?: string }) =>
        (t.type === 'http-request' && t.name?.includes('www.volvocars.com')) ||
        t.type !== 'http-request',
    );
    return payload;
  },
});

export interface PageProps {
  featureFlags?: RawFeatureFlags;
  marketConfig?: MarketConfigurations;
  dealerPage?: { name?: string };
}

const App = ({
  Component,
  pageProps,
  renderer,
  router,
}: {
  Component: NextComponentType<NextPageContext, AppInitialProps, PageProps>;
  pageProps: AppProps['pageProps'] & PageProps;
  renderer: StyleRenderer;
  router: AppProps['router'];
}) => {
  return (
    <ErrorBoundary>
      <GTMSnippets />
      {withProviders(renderer)(Component)({ pageProps, router })}
    </ErrorBoundary>
  );
};
export default App;
