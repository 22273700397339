import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { generateUUID } from './utils/generateUUID';
import { Retailer } from '../types/retailer';
import { AutocompleteResponse } from '../types/maps';

export type StoreContextValue = State & {
  dispatch: React.Dispatch<StoreAction>;
};

const initialState: State = {
  view: 'map',
  sessiontoken: generateUUID(),
  address: null,
  selectedRetailer: null,
  retailersListVisible: false,
  expandedCardEnabled: false,
};
export type State = {
  view: 'list' | 'map';
  sessiontoken?: string;
  address:
    | (AutocompleteResponse['data'][number] & {
        coords?: { longitude: number; latitude: number };
      })
    | null;
  selectedRetailer: Retailer | null;
  retailersListVisible: boolean;
  expandedCardEnabled: boolean;
};
export const StoreContext = createContext<StoreContextValue>({
  ...initialState,
  dispatch: () => {},
});

type StoreAction =
  | { type: 'SET_VIEW'; payload: State['view'] }
  | { type: 'REFRESH_SESSION_TOKEN' }
  | { type: 'SET_ADDRESS'; payload: State['address'] }
  | { type: 'SET_SELECTED_RETAILER'; payload: State['selectedRetailer'] }
  | { type: 'SET_EXPANDED_CARD_ENABLED'; payload: State['expandedCardEnabled'] }
  | {
      type: 'SET_RETAILERSLIST_VISIBLE';
      payload: State['retailersListVisible'];
    };

function getState<T extends keyof State>(
  oldState: State,
  payload: State[T],
  prop: T,
): State;
function getState(oldState: State, payload: any, prop: keyof State) {
  return oldState[prop] === payload
    ? oldState
    : { ...oldState, [prop]: payload };
}
function reducer(state: State, action: StoreAction): State {
  switch (action.type) {
    case 'SET_VIEW':
      return getState(state, action.payload, 'view');
    case 'REFRESH_SESSION_TOKEN':
      return getState(state, generateUUID(), 'sessiontoken');
    case 'SET_ADDRESS':
      return getState(state, action.payload, 'address');
    case 'SET_SELECTED_RETAILER':
      return getState(state, action.payload, 'selectedRetailer');
    case 'SET_EXPANDED_CARD_ENABLED':
      return getState(state, action.payload, 'expandedCardEnabled');
    case 'SET_RETAILERSLIST_VISIBLE':
      return getState(state, action.payload, 'retailersListVisible');
    default:
      return state;
  }
}
type StoreProviderProps = React.PropsWithChildren<unknown>;
export const StoreProvider = ({
  children,
}: StoreProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateWithDispatch = useMemo(() => {
    return {
      ...state,
      dispatch,
    };
  }, [state, dispatch]);

  return (
    <StoreContext.Provider value={stateWithDispatch}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
