import React, { createContext, useContext } from 'react';
import { ConfigurationKey } from '../constants/marketConfigs';
import { Capability } from '../types/retailerCapabilities';

type MarketGeneralConfigurationsObject = {
  [key in ConfigurationKey]: boolean;
};

export type MarketConfigurations = MarketGeneralConfigurationsObject & {
  disabledFilters: Capability[];
  firstDayOfWeek: 'monday' | 'sunday';
};

export const initialMarketConfig: MarketConfigurations = {
  mapReboundMaxRadius1000km: false,
  useAorRetailersAndZipCodeSearch: false,
  coordinatesMapLink: false,
  disabledFilters: [],
  firstDayOfWeek: 'monday',
};

const MarketConfigContext =
  createContext<MarketConfigurations>(initialMarketConfig);

type MarketConfigProviderProps = React.PropsWithChildren<{
  config: MarketConfigurations;
}>;

export const MarketConfigProvider = ({
  children,
  config,
}: MarketConfigProviderProps): JSX.Element => (
  <MarketConfigContext.Provider value={config}>
    {children}
  </MarketConfigContext.Provider>
);

export const useMarketConfig = () => useContext(MarketConfigContext);
