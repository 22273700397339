import { attachOnVolvoCarsAnalyticsGlobal } from './attachOnVolvoCarsAnalyticsGlobal';

export function attachGetDimension() {
  attachOnVolvoCarsAnalyticsGlobal('getDimension', (node: string) => {
    // eslint-disable-next-line no-restricted-properties
    const foundNode = window.dataLayer?.findLast?.((event) => event);
    return typeof foundNode === 'object' &&
      !Array.isArray(foundNode) &&
      foundNode !== null
      ? foundNode[node as keyof typeof foundNode]
      : undefined;
  });
}
