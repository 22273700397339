export enum ErrorMessages {
  UNCAUGHT_ERROR = 'UNCAUGHT_ERROR',
  /* DEALER PAGE */
  DEALER_PAGE_NOT_FOUND = 'DEALER_PAGE_NOT_FOUND',
  DEALER_PAGE_RETAILER_FETCH_ERROR = 'DEALER_PAGE_RETAILER_FETCH_ERROR',
  DEALER_PAGE_DICTIONARY_FETCH_ERROR = 'DEALER_PAGE_DICTIONARY_FETCH_ERROR',
  /* SELECTOR PAGE */
  SELECTOR_DICTIONARY_FETCH_ERROR = 'SELECTOR_DICTIONARY_FETCH_ERROR',
  SELECTOR_RETAILER_LOCATIONS_FETCH_ERROR = 'SELECTOR_RETAILER_LOCATIONS_FETCH_ERROR',
  /* GENERAL PAGE */
  EXPANDED_CARD_SHEET_INVALID_RETAILER = 'EXPANDED_CARD_SHEET_INVALID_RETAILER',
  MAIN_RETAILER_SELECTOR_INVALID_SITE_SLUG = 'MAIN_RETAILER_SELECTOR_INVALID_SITE_SLUG',
  MAIN_RETAILER_LOCATOR_INVALID_SITE_SLUG = 'MAIN_RETAILER_LOCATOR_INVALID_SITE_SLUG',
  RETAILER_TRANSLATION_ERROR = 'RETAILER_TRANSLATION_ERROR',
  RETAILER_RESPONSE_DATA_NOT_FOUND = 'RETAILER_RESPONSE_DATA_NOT_FOUND',
  API_FETCH_ERROR = 'API_FETCH_ERROR',
  DEALER_LOCATOR_RETAILER_FETCH_ERROR = 'DEALER_LOCATOR_RETAILER_FETCH_ERROR',
  RETAILER_LOCATIONS_FETCH_ERROR = 'RETAILER_LOCATIONS_FETCH_ERROR',
  ADDRESS_COORDS_PLACE_DETAILS_FETCH_ERROR = 'ADDRESS_COORDS_PLACE_DETAILS_FETCH_ERROR',
  PLACE_COORDINATES_PLACE_DETAILS_FETCH_ERROR = 'PLACE_COORDINATES_PLACE_DETAILS_FETCH_ERROR',
  GOOGLE_MAPS_PLACE_DETAILS_FETCH_ERROR = 'GOOGLE_MAPS_PLACE_DETAILS_FETCH_ERROR',
  GOOGLE_MAPS_COORDS_NOT_FOUND = 'GOOGLE_MAPS_COORDS_NOT_FOUND',
  AOR_RETAILER_FETCH_ERROR = 'AOR_RETAILER_FETCH_ERROR',
  AOR_RETAILER_REVERSE_GEOCODE_FETCH_ERROR = 'AOR_RETAILER_REVERSE_GEOCODE_FETCH_ERROR',
  GOOGLE_MAPS_REVERSE_GEOCODE_FETCH_ERROR = 'GOOGLE_MAPS_REVERSE_GEOCODE_FETCH_ERROR',
  AUTOCOMPLETE_FETCH_ERROR = 'AUTOCOMPLETE_FETCH_ERROR',
  DISTANCE_METRIX_FETCH_ERROR = 'DISTANCE_METRIX_FETCH_ERROR',
  QUERY_PATH_ERROR = 'QUERY_PATH_ERROR',
  FEATURE_FLAGS_NOT_PROVIDED = 'FEATURE_FLAGS_NOT_PROVIDED',
  FORMAT_DISTANCE_INVALID_LOCALE = 'FORMAT_DISTANCE_INVALID_LOCALE',
  AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS = 'AOR_RETAILER_CONTRADICTING_INPUT_PARAMETERS',
  NO_INITIAL_RETAILERS = 'NO_INITIAL_RETAILERS',
  /* UTILS */
  MISSING_CONTENT_DELIVERY_KEY = 'MISSING_CONTENT_DELIVERY_KEY',
  MISSING_SHARED_CONTENT_DELIVERY_KEY = 'MISSING_SHARED_CONTENT_DELIVERY_KEY',
  SHARED_TRANSLATION_ERROR = 'SHARED_TRANSLATION_ERROR',
  /* API */
  AOR_RETAILER_WITHOUT_PARTNERCODE = 'AOR_RETAILER_WITHOUT_PARTNERCODE',
  MULTIPLE_PARAMS_ERROR = 'MULTIPLE_PARAMS_ERROR',
}
