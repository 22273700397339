import React from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { AppInitialProps, AppProps } from 'next/app';
import { PageProps } from 'pages/_app';
import { StyleProvider, StyleRenderer, ThemePicker } from 'vcc-ui';
import Cookies from 'js-cookie';
import { BreakpointsProvider } from '@vcc-www/hooks';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { urlFromString, VolvoCarsUrlProvider } from '@vcc-www/volvo-cars-url';
import { getMarketSite } from '@volvo-cars/market-sites';
import { TrackingProvider } from '@volvo-cars/tracking';
import { FeatureFlagsProvider } from '@vcc-www/feature-flags';
import { RawFeatureFlags } from 'src/utils/parseFeatureFlags';
import { getPublicEnv } from 'src/public-env';
import { getStyleRenderer } from 'src/utils/getStyleRenderer';
import { featureFlagsToString } from '../utils/volvoTrackingHelpers';
import { GA4_ANALYTICS_COOKIE, TRACKING_CATEGORY } from '../constants/tracking';
import { getLaunchDarklyUserKey } from '../utils/getLaunchDarklyUser';
import {
  StoreProvider,
  MarketConfigProvider,
} from '@vcc-package/retailer-selector';
import { getValidSiteSlug } from 'src/utils/getValidSiteSlug';

const launchDarklyClientName = 'dealer-locator';

const withProviders =
  (renderer: StyleRenderer) =>
  (Component: NextComponentType<NextPageContext, AppInitialProps, PageProps>) =>
    function EnhancedApp({
      pageProps,
      router,
    }: {
      pageProps: AppProps['pageProps'] & PageProps;
      router: AppProps['router'];
    }): JSX.Element {
      const rawFeatureFlags = pageProps.featureFlags as
        | RawFeatureFlags
        | undefined;
      const marketConfig = pageProps.marketConfig;
      const { locale, regionCode, siteSlug } = useCurrentMarketSite();
      const isDwS =
        pageProps?.dealerPage?.name?.toLowerCase() === 'dealerpage' &&
        router?.pathname?.split('/')?.[4]?.toLowerCase() === '[...dealerinfo]';
      const isSelector = router?.pathname?.split('/')?.[3] === 'selector';
      const pageName = isDwS
        ? 'information'
        : isSelector
          ? 'selector'
          : 'locator';
      const subPageName = isSelector ? 'preorder' : undefined;
      const publicRuntimeConfig = getPublicEnv();

      const gaCookie = Cookies.get(GA4_ANALYTICS_COOKIE);

      const featureFlagString = featureFlagsToString(rawFeatureFlags);
      return (
        <StyleThemeProvider
          renderer={renderer}
          direction={getMarketSite(router.asPath)?.languageDirection}
        >
          <VolvoCarsUrlProvider url={urlFromString(router.asPath)}>
            <TrackingProvider
              countryCode={regionCode}
              featureFlag={featureFlagString}
              forceLowerCase={false}
              marketLanguage={locale}
              pageName={pageName}
              pageType="dealer"
              subPageName={subPageName}
              trackPageLoad
              eventCategory={TRACKING_CATEGORY}
            >
              <BreakpointsProvider>
                <StoreProvider>
                  <FeatureFlagsProvider
                    customAttributes={{ siteSlug: getValidSiteSlug(siteSlug) }}
                    clientName={launchDarklyClientName}
                    ldClientId={publicRuntimeConfig.launchDarklyClientId}
                    ldUserKey={getLaunchDarklyUserKey(
                      siteSlug || 'intl',
                      gaCookie,
                    )}
                    ldOptions={{
                      evaluationReasons: true,
                      sendEventsOnlyForVariation: true,
                      sendEvents: true,
                    }}
                    initialFlags={{
                      flags: rawFeatureFlags,
                    }}
                  >
                    <MarketConfigProvider config={marketConfig}>
                      <Component {...pageProps} />
                    </MarketConfigProvider>
                  </FeatureFlagsProvider>
                </StoreProvider>
              </BreakpointsProvider>
            </TrackingProvider>
          </VolvoCarsUrlProvider>
        </StyleThemeProvider>
      );
    };

export default withProviders;

const defaultStyleRenderer = getStyleRenderer();

type StyleThemeProviderProps = React.PropsWithChildren<{
  renderer?: StyleRenderer;
  direction?: 'ltr' | 'rtl';
}>;
const StyleThemeProvider = ({
  renderer = defaultStyleRenderer,
  direction,
  children,
}: StyleThemeProviderProps): JSX.Element => (
  <StyleProvider renderer={renderer}>
    <ThemePicker direction={direction}>{children}</ThemePicker>
  </StyleProvider>
);
