import { attachOnVolvoCarsAnalyticsGlobal } from './attachOnVolvoCarsAnalyticsGlobal';
import { PageViewData } from './types';

export class PageViewCache {
  private pageView:
    | (PageViewData & { previousPageName?: string; previousPageUrl?: string })
    | null;
  constructor() {
    this.pageView = null;
  }

  public set(pageView: PageViewData) {
    this.pageView = pageView;
  }

  public get() {
    return this.pageView;
  }
}

// Singleton across apps
export function getPageViewCache() {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.VolvoCarsAnalytics?.pageViewCache) {
    const pageViewCache = new PageViewCache();
    attachOnVolvoCarsAnalyticsGlobal('pageViewCache', pageViewCache);

    return pageViewCache;
  }

  return window.VolvoCarsAnalytics.pageViewCache;
}
